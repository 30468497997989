import { extendTheme } from '@chakra-ui/react'
import { FieldBox } from 'lib/chakra-components'

const config: object = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme: object = extendTheme({
  config,
  components: { FieldBox },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: {
    textPrimary: {
      dark: 'black',
      light: 'white',
    },
    textSecondary: {
      light: 'gray.400',
      dark: 'gray.500',
    },
    background: {
      dark: '#1A202C',
      light: 'white',
    },
  },
})
