import { ChakraProvider } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import Layout from 'components/layout'
import { theme } from 'pages/app-functions'
import ErrorPage from 'pages/wrong'
import '../styles/globals.css'
import { SessionProvider } from 'next-auth/react'

function MyApp({ Component, pageProps, session }) {
  return (
    <ErrorBoundary fallback={(props) => <ErrorPage error={props.error} />}>
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ChakraProvider>
      </SessionProvider>
    </ErrorBoundary>
  )
}

export default MyApp
