import { mode } from '@chakra-ui/theme-tools'
import type { ComponentStyleConfig } from '@chakra-ui/theme'

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export const FieldBox: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: (props) => ({
    backgroundColor: mode('background.light', 'background.dark')(props),
    color: mode('gray.400', 'gray.500')(props),
    shadow: 'inset 0 0 5px 1px rgba(0,0,0,0.2) ',
    borderRadius: '0.5em',
  }),

  variants: {
    pickerInput: {
      paddingY: '0.5em',
      paddingX: '1em',
    },
  },
  // The default size and variant values
}
